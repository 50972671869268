import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { find, isEmpty } from 'lodash-es';

import { utils } from "../../../libs/utils";
import { selectPurchasedProductList } from "../../../store/voice";
import { voiceManager } from "../../../managers";
import { tmapLogbox, useCustomPageIdForLogbox } from "../../../libs/logbox";
import { CouponProducts, ProductItem } from "../../../types/voice";

import { CelebVoiceCouponItem } from "./CelebVoiceCouponItem";
import { Footer, HeaderVoiceCoupon } from "../../../components/Layout";
import { modal } from "../../../components/GlobalModal";

import emptyImage from '../../../assets/images/no_event_product.svg';
import { globalVars } from '../../../libs/global-vars';

interface Props {
  couponProducts: CouponProducts;
  couponNumber: string;
  goBack(): void;
}

function VoiceCouponItemList({ couponProducts, couponNumber, goBack }: Props) {
  const dispatch = useDispatch();
  const [inited, setInited] = useState(false);
  const [openCaution, setOpenCaution] = useState(false);
  const purchasedProductList = useSelector(selectPurchasedProductList);
  const promotionId = useMemo(() => couponProducts.promotionId || '', [couponProducts]);
  const productList = useMemo(() => {
    if(inited && couponProducts.products) {
      const list =  couponProducts.products.filter((item) =>
        !find(purchasedProductList, purchaseItem => purchaseItem.productId === item?.productId));

      if(couponProducts && isEmpty(list)) setOpenCaution(true);
      return list;
    }
  }, [inited, couponProducts, purchasedProductList]);
  const expireDateStr = useMemo(() => {
    if(couponProducts.couponExpireDate) {
     const date = utils.dateText(couponProducts.couponExpireDate, 'date').split(".");
     return `${date[0]}년 ${date[1]}월 ${date[2]}일`;
    }
  }, [couponProducts]);

  useEffect(() => {
    if(openCaution) tmapLogbox.sendEvent('tap.voucher.notice', { promotion_id: promotionId });
  }, [openCaution, promotionId]);

  useEffect(() => {
    // 스킴으로 진입시에는 정보 없음
    if(!inited && globalVars.isFirstLanding) {
      const fetchList = [voiceManager.fetchProductList(), voiceManager.fetchExpiredProductList()];
      Promise.all(fetchList).then(() => setInited(true));
    } else setInited(true);
  }, [dispatch, inited]);

  // 로그박스를 위한 custom pageId 설정
  const customPageIdCallback = useCallback((pageId: string) => {
    return pageId.replace('main', 'voucher');
  }, []);
  useCustomPageIdForLogbox(customPageIdCallback);

  const purchaseCouponByPromotionId = (itemData: ProductItem) => {
    voiceManager.paymentAgreeCheck().then(() => {
      modal.couponRegisterConfirm(promotionId, itemData, (item: ProductItem) => {
        voiceManager.purchaseByCoupon(item, couponNumber, promotionId);
      });
    });
  };

  return (
    <>
      <HeaderVoiceCoupon title={'셀럽 이벤트 이용권'} goBack={goBack} />
      {inited && (
        <div className="container">
          <div className="content">
            {expireDateStr && <p className="voice_coupon_period">유효기간: {expireDateStr}까지</p>}
            {
              productList && !isEmpty(productList) ? (
                <section className="celeb_voice">
                  <ul className="celeb_voice_list">
                    {productList!.map(itemData => (
                      <CelebVoiceCouponItem
                        promotionId={promotionId}
                        itemData={itemData}
                        key={itemData.productId}
                        onClickUse={purchaseCouponByPromotionId}
                      />
                    ))}
                  </ul>
                </section>
              ) : (
                <div className="voice_coupon_empty">
                  <img className="img" src={emptyImage} alt="" />
                  <p className="title">선택 가능한 상품이<br/>없습니다.</p>
                </div>
              )
            }
          </div>
          <div className="voice_coupon_notice">
            <div className="voice_detail_notice">
              <p
                className={`title ${openCaution ? 'unfold' : ''}`}
                onClick={() => {
                  const value = !openCaution;
                  setOpenCaution(value);
                }}
              >
                유의사항
              </p>
              {openCaution && (
                <ul className="voice_coupon_desc">
                  <li>- 이벤트 사정에 따라 조기 종료될 수 있습니다.</li>
                  <li>- 등록일부터 이용기간 동안 티맵 길안내 목소리를 이용하실 수 있습니다.</li>
                  <li>- 등록 후 이용기간이나 셀럽을 변경하실 수 없습니다.</li>
                  <li>- 사용자에 따라 선택 가능한 셀럽이 다를 수 있습니다.</li>
                  <li>- 유효기간이 지나면 이용하실 수 없으며 현금으로 보상받으실 수 없습니다.</li>
                  <li>- 유효기간 내 등록해주세요.</li>
                  <li>- 이용문의 메뉴 {'>'} 고객센터 1:1 문의</li>
                </ul>
              )}
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export { VoiceCouponItemList };
