import React from 'react';
import { FadeInByScroll } from '../FadeInByScroll';
import { HeaderBackButton } from './HeaderBackButton';

function Header({ title }: { title?: string }) {

  return (
    <header className="header" style={{ backgroundColor: '#fff' }}>
      <h1 className="title">{title || '길안내 목소리'}</h1>
      <HeaderBackButton />
      <FadeInByScroll className="bg" applyDistance={5} />
    </header>
  );
}

export { Header };
