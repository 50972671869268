import { useEffect } from "react"
import { tmapLogbox } from "."

export const useCustomPageIdForLogbox = (callback: (pageid: string) => string) => {
  useEffect(() => {
    const beforePageId = tmapLogbox.currentPageId
    tmapLogbox.setCurrentPageId(callback(tmapLogbox.currentPageId))
    tmapLogbox.sendExpose()
    return () => {
      tmapLogbox.setCurrentPageId(beforePageId)
      tmapLogbox.sendExpose()
    }
  }, [callback])
}