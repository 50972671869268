import React from 'react';
import { FadeInByScroll } from '../FadeInByScroll';

interface Props {
  title?: string;
  goBack(): void;
}

function HeaderVoiceCoupon({ title, goBack  }: Props) {
  return (
    <header className="header">
      <h1 className="title">{title || '셀럽 보이스 이용권'}</h1>
      <button type="button" className="btn_coupon_back" onClick={goBack}/>
      <FadeInByScroll className="bg" applyDistance={5} />
    </header>
  );
}

export { HeaderVoiceCoupon };
