import React, { useMemo } from "react";
import { Footer, Header } from "../../../components/Layout";

interface Props {
  couponNumber: string;
  errorMsg: string;
  setCouponNumber(couponNumber: string): void;
  registerCoupon(): void;
}

function CouponRegister({couponNumber, errorMsg, setCouponNumber, registerCoupon}: Props) {
  const validate = useMemo(() => {
    if (couponNumber.length !== 12) return false;
    return couponNumber === couponNumber.replace(/[^A-Za-z0-9]/g, '');
  }, [couponNumber]);

  return (
    <>
      <Header title={'쿠폰'} />
      <div className="container">
        <div className="content">
          <section className="voice_coupon">
            <h1 className="title">쿠폰등록</h1>
            <div className="voice_coupon_input">
              <input
                type="text"
                className="ipt"
                placeholder="쿠폰번호 입력(12자리)"
                value={couponNumber}
                onChange={e => setCouponNumber(e.target.value.slice(0, 12))}
                maxLength={12}
                autoFocus
              />
              <button
                type="button"
                className={`btn_confirm`}
                disabled={!validate}
                onClick={() => registerCoupon()}
              >
                등록
              </button>
            </div>
            {errorMsg && <p className="voice_coupon_error" dangerouslySetInnerHTML={{ __html: errorMsg }} />}
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export { CouponRegister };