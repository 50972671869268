import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash-es';
import { eventBus } from '../../libs/event-bus';
import { Alert, VoiceServiceAgreement, CouponRegisterConfirm } from './Items';
import { HistoryManagerEvent } from '../HistoryManager';
import { useListenEventBus, useListenWindowEvent } from '../../libs/custom-hooks';
import { ProductItem } from "../../types/voice";

export enum ModalEvent {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

function GlobalModal() {
  const [currentComp, setCurrentComp] = useState(null);

  // methods
  const open = useCallback(
    targetComp => {
      if (currentComp) throw Error(`Modal 중복호출 : ${targetComp.toString()}`);
      const hasModalFlag = get(window, 'history.state.state.modal');
      if (!currentComp && !hasModalFlag) {
        const { pathname, search } = window.location;
        eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, `${pathname}${search}`, { modal: true });
      }
      setCurrentComp(targetComp);
    },
    [currentComp],
  );

  const close = useCallback(
    callback => {
      const hasModalFlag = get(window, 'history.state.state.modal');
      if (currentComp && hasModalFlag) eventBus.emit(HistoryManagerEvent.GO_BACK);
      // 다음 이벤트큐에서 history가 변경됨. callback에서 history변경시 오류 가능성 있음. 변경된 후 callback 호출.
      if (typeof callback === 'function') setTimeout(() => callback(), 0);
      setCurrentComp(null);
    },
    [currentComp],
  );

  useEffect(() => {
    document.body.style.overflow = currentComp ? 'hidden' : '';
  }, [currentComp]);

  // listen eventbus
  useListenEventBus(ModalEvent.OPEN_MODAL, open);
  useListenEventBus(ModalEvent.CLOSE_MODAL, close);
  useListenWindowEvent('popstate', close);

  return <>{currentComp}</>;
}

const modal = {
  alert: (content: string, callback?: Nullable<(a: any) => any>, title?: string) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <Alert {...{ content, callback, title }} />);
  },
  voiceServiceAgreement: (callback?: Nullable<(a: any) => any>, value?: boolean) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <VoiceServiceAgreement {...{ callback, value }} />);
  },
  couponRegisterConfirm: (promotionId: string, itemData: ProductItem, callback?: Nullable<(a: any) => any>) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <CouponRegisterConfirm {...{ promotionId, itemData, callback }} />);
  },
};

export { GlobalModal, modal };
