import React, { useEffect, useRef, useCallback, MutableRefObject, ReactElement } from 'react';
import { get } from 'lodash-es';
import { utils } from '../libs/utils';

interface Props {
  targetRef?: MutableRefObject<HTMLElement | null>;
  checkPoint?: number;
  applyDistance?: number;
  children?: ReactElement | ReactElement[];
  [key: string]: any;
}

function FadeInByScroll(props: Props) {
  const el = useRef(null);
  const { targetRef, checkPoint = 0, applyDistance = 50, children, ...defaultProps } = props;

  const onScroll = useCallback(() => {
    const endY = targetRef && targetRef.current ? utils.getOffsetTop(targetRef.current) + targetRef.current.offsetHeight + checkPoint : 0;
    const baseY = window.scrollY - endY;
    let opacity = baseY / applyDistance;
    if (opacity < 0) opacity = 0;
    if (opacity > 1) opacity = 1;
    if (el.current) {
      const currentOpacity = Number(get(el, 'current.style.opacity'));
      //@ts-ignore
      if (currentOpacity !== opacity) el.current.style.opacity = String(opacity);
    }
  }, [targetRef, checkPoint, applyDistance]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <div {...defaultProps} ref={el}>
      {children}
    </div>
  );
}

export { FadeInByScroll };
