import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import qs from "qs";

import { RootState } from "../../store";
import { voiceManager, voiceQueryString } from "../../managers";
import { tmapLogbox } from "../../libs/logbox";

import { CouponRegister, VoiceCouponItemList } from "./components";

function VoiceCoupon(props: RouteComponentProps) {
  const couponProducts = useSelector((state: RootState) => state.voice.couponProducts);
  const promotionId = useMemo(() => couponProducts?.promotionId || '', [couponProducts]);
  const [isMainLayout, setIsMainLayout] = useState<boolean>(true);
  const [couponNumber, setCouponNumber] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    const queryParam : voiceQueryString = qs.parse(props.location.search.replace(/^\?/, ''));
    if('coupon' === queryParam.promotionKey && queryParam.promotionValue) setCouponNumber(queryParam.promotionValue);
  }, [props.location.search]);

  useEffect(() => {
    if(isMainLayout && couponProducts) setIsMainLayout(false);
  }, [couponProducts, isMainLayout])

  const registerCoupon = useCallback(() => {
    const value = couponNumber.trim();
    if (!value) {
      setErrorMsg('쿠폰번호를 입력해주세요.');
      return;
    }

    voiceManager.registerCoupon(couponNumber).then((message) => {
      if(message) setErrorMsg(message);
      else setErrorMsg("");
    });
  }, [couponNumber]);

  function onChangeCouponNumber(couponNumber: string) {
    setCouponNumber(couponNumber);
  }

  function changeToMainLayout() {
    tmapLogbox.sendEvent('tap.back',  { promotion_id: promotionId });
    voiceManager.resetCouponProducts();
    setIsMainLayout(true);
  }

  return (
    <>
      {
        isMainLayout ?
          <CouponRegister
            couponNumber={couponNumber}
            errorMsg={errorMsg}
            setCouponNumber={onChangeCouponNumber}
            registerCoupon={registerCoupon}
          />
          : couponProducts && (
            <VoiceCouponItemList
              couponProducts={couponProducts}
              couponNumber={couponNumber}
              goBack={changeToMainLayout}
            />
          )
      }
    </>
  );
}

export { VoiceCoupon };
