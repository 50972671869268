import React, { useCallback, useMemo } from 'react';
import { eventBus } from '../../../libs/event-bus';
import { ModalEvent } from '../index';
import { ProductItem } from "../../../types/voice";

interface Props {
  promotionId: string;
  itemData: ProductItem;
  callback?: Nullable<(a: any) => any>;
}

function CouponRegisterConfirm({ promotionId, itemData, callback }: Props) {
  const usagePeriod = useMemo(() => {
      if(itemData) return itemData.usagePeriod === '365' ? '1년' : `${itemData.usagePeriod}일`;
      else return '';
    },
    [itemData]);

  const onClickCancel = () => {
    eventBus.emit(ModalEvent.CLOSE_MODAL);
  };

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
        if (callback) callback(itemData);
      });
    }, [itemData, callback]);
  const logBoxCustom = JSON.stringify({ product_id: itemData.productId, promotion_id: promotionId });

  return (
    <div className="modal_wrap">
      <div className="modal_container">
        <header className="modal_header">
          <h1 className="title coupon">{itemData.name || ''}</h1>
        </header>
        <form onSubmit={onSubmit}>
          <div className="modal_coupon_content">
            <p className="msg">오늘부터 {usagePeriod} 동안<br/>이용하실 수 있습니다.&#x1F697;</p>
            <p className="sub_msg">선택 후 변경하실 수 없습니다.</p>
          </div>
          <footer className="modal_footer">
            <button
              type="button"
              className="btn_cancel"
              onClick={() => onClickCancel()}
              data-logbox-action="tap.popup.close"
              data-logbox-custom={logBoxCustom}
            >
              닫기
            </button>
            <button
              type="submit"
              className="btn_confirm"
              data-logbox-action="tap.voucher.success"
              data-logbox-custom={logBoxCustom}
            >
              이용하기
            </button>
          </footer>
        </form>
      </div>
      <div className="modal_dimmed" />
    </div>
  );
}

export { CouponRegisterConfirm };
