import React, { useRef, useEffect, useCallback } from 'react';

import { Header } from '../../components/Layout';
import { utils } from '../../libs/utils';

import { DefaultVoiceList } from './components';

function VoiceInfo() {
  const btnRef = useRef(null);
  const dummyRef = useRef(null);

  const onScroll = useCallback(() => {
    const bottomCap = 20;
    // @ts-ignore
    const posY = utils.getOffsetTop(dummyRef.current) + dummyRef.current.offsetHeight + bottomCap - window.innerHeight;
    if (window.scrollY > posY) {
      //@ts-ignore
      btnRef.current.classList.remove('fixed');
      //@ts-ignore
      dummyRef.current.classList.add('hide');
    } else {
      //@ts-ignore
      btnRef.current.classList.add('fixed');
      //@ts-ignore
      dummyRef.current.classList.remove('hide');
    }
  }, [btnRef, dummyRef]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <>
      <Header title={'길안내 목소리'} />
      <div className="content">
        {/* 기본보이스 */}
        <section className="voice_type">
          <DefaultVoiceList />
        </section>

        <section className="red_app">
          <div className="red_app_info">
            <p className="desc">
              지금 사용하시는 앱 버전에서는 <span className="line_break">셀럽 길안내 목소리를 지원하지 않습니다.</span>
            </p>
            <div className="img">
              <img
                src={require('../../assets/images/invalid-name.png').default}
                srcSet={`
                  ${require('../../assets/images/invalid-name@2x.png').default} 2x,
                  ${require('../../assets/images/invalid-name@3x.png').default} 3x
                `}
                className="img"
                alt=""
              />
            </div>
          </div>
          <div className="red_app_update">
            <p className="desc">
              <i className="point">구글 플레이스토어</i>에서 <span className="line_break">TMAP 최신버전으로 업데이트하고</span>{' '}
              <span className="line_break">새로운 길안내를 경험하세요.</span>
            </p>
            <span className="btn_dummy" ref={dummyRef}>
              &nbsp
            </span>
            <a
              href="#app-download"
              className="btn_update fixed"
              ref={btnRef}
              onClick={e => {
                e.preventDefault();
                utils.linkToAppDownload();
              }}
            >
              최신 버전 다운받기
            </a>
          </div>
          <ul className="red_app_payment">
            <li className="item">TMAP 셀럽은 구글 플레이스토어를 통해 TMAP을 다운받으셔야 사용 가능합니다.</li>
            <li className="item">
              결제는 플레이스토어 결제만 가능하며, 플레이스토어에 등록된 결제수단이 기존에 없을 경우 등록과정이 필요합니다.
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}

export { VoiceInfo };
