import { createSlice } from '@reduxjs/toolkit';
import { globalVars } from '../libs/global-vars';
import { AppState } from '../types/app';

const initialState: AppState = {
  accessKey: globalVars.accessKey || '',
  globalLoading: 0,
  globalCover: false,
  systemError: null,
  isDevelopmentBuild: /STG|DTG|DEV/.test(process.env.REACT_APP_BUILD_ENV || '') || process.env.NODE_ENV === 'development'
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAccessKey(state, action) {
      state.accessKey = action.payload;
    },
    globalLoadingInc(state) {
      state.globalLoading++;
    },
    globalLoadingDec(state) {
      state.globalLoading--;
    },
    setGlobalCover(state, action) {
      state.globalCover = action.payload;
    },
    setSystemError(state, action) {
      if (!state.systemError) state.systemError = action.payload;
    },
  },
});

export default appSlice;
