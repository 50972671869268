import { datadogRum } from '@datadog/browser-rum'
import { globalVars } from './global-vars'

const isDevBuild = globalVars.isDevelopmentBuild
const disabled = globalVars.isLocal

export const datadog = {
  init() {
    if (disabled) return
    datadogRum.init({
      applicationId: 'ddbc4b0c-53b4-4a6b-983d-34c20315abb9',
      clientToken: 'pub8c9e1afc28beae36ce0b219f599844d3',
      site: 'datadoghq.com',
      service:'tmap-voice',
      env: (process.env.REACT_APP_BUILD_ENV || 'UNKNOWN').toLocaleLowerCase(),
      // Specify a version number to identify the deployed version of your application in Datadog 
      version: process.env.BUILD_NUMBER,
      sampleRate: isDevBuild ? 100 : 10,
      trackInteractions: true
    })
  },
  addAction(message: string, context?: any) {
    if (disabled) return
    datadogRum.addAction(message, context)
  },
  addError(error: unknown, context?: any) {
    if (disabled) return
    datadogRum.addError(error, context)
  },
}
