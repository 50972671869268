import { useEffect } from 'react';
import { eventBus } from './event-bus';

const useListenEventBus = function (event: string, callback: (...args: any[]) => any) {
  useEffect(() => {
    eventBus.on(event, callback);
    return () => {
      eventBus.off(event, callback);
    };
  }, [callback, event]);
};

const useListenWindowEvent = function (event: string, callback: (...args: any[]) => any, capture: boolean = false) {
  useEffect(() => {
    window.addEventListener(event, callback, capture);
    return () => {
      window.removeEventListener(event, callback, capture);
    };
  }, [callback, event, capture]);
};

export { useListenEventBus, useListenWindowEvent };
