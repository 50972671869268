import axios, { AxiosRequestConfig } from 'axios';
import { get } from 'lodash-es';
import {} from 'react-redux';
import { globalVars } from './global-vars';
import { utils } from './utils';
import { appManager } from '../managers';
import { tmapInterface } from './tmap-interface';
import { datadog } from './datadog-rum';

const $tmapAxios = axios.create(); // 나중에 header 설정해야함.

export interface TmapAxiosRequestConfig extends AxiosRequestConfig {
  skipErrorHandler: Nullable<boolean>;
  loading: Nullable<boolean>;
}

const configDefaults = {
  skipErrorHandler: false,
  loading: true,
};

// 공통헤더 세팅
$tmapAxios.interceptors.request.use(config => {
  config.data = config.data || {};
  const httpHeaders = (config.headers = config.headers || {});
  const escBodyHeader = (config.data.header = config.data.header || {});

  Object.assign(httpHeaders, {
    AccessKey: globalVars.accessKey,
    AP_CODE: 'ESC_AP_TMAPWEB',
    AppCode: globalVars.isGreenApp ? 'TMAP_KU' : 'TMAP',
  });

  Object.assign(escBodyHeader, {
    reqTime: utils.dateToText(),
    svcRequester: 'starvoice',
    osType: globalVars.isAndroid ? 'AND' : 'IOS',
    userId: globalVars.mdn,
    appVersion: globalVars.appVersion,
  });
  return config;
});

// store loading state setting
$tmapAxios.interceptors.request.use(config => {
  config = Object.assign({}, configDefaults, config);
  if ((config as TmapAxiosRequestConfig).loading) appManager.globalLoadingInc();
  datadog.addAction(`REQUEST__${config.url}`, {requestHeaders: config.headers, requestData: config.data})
  return config;
});
$tmapAxios.interceptors.response.use(
  response => {
    const { data, headers, config } = response;
    const { loading, skipErrorHandler } = response.config as TmapAxiosRequestConfig;
    if (loading) appManager.globalLoadingDec();
    datadog.addAction(`FETCHED__${config.url}`, {requestHeaders: config.headers, requestData: config.data, responseData: data})
    if (skipErrorHandler) return response;

    /*
      accessKey 갱신시 업데이트 - accessKey 만료시 서버에서 재발급 후 응답줌
    */
    const responseAccessKey = headers.accesskey || get(data, 'header.accessKey');
    if (responseAccessKey && responseAccessKey !== globalVars.accessKey) {
      globalVars.accessKey = responseAccessKey;
      tmapInterface.updateAccessKey(responseAccessKey);
    }

    /** 공통에러
     * 000000 성공
     * 190401 외부연동 조회결과 없음 (배너목록)
     * 250103 구매이력 없음
     * 200301 이용약관 조회된 결과 없음
     * 090501 과금 조회된 결과 없음
     */
    const successCodes = ['000000', '190401', '250103', '200301', '090501'];
    const responseErrorCode = get(response, 'data.header.errorCode');
    const isError = !successCodes.includes(responseErrorCode);
    if (isError) {
      const message = get(
        response,
        'data.header.errorMessage',
        '사용자 정보 요청중<br/>통신 장애가 발생하였습니다.<br/>잠시 후 다시 시도해주세요.',
      );
      appManager.setSystemError({ code: responseErrorCode, message });
    }
    return response;
  },
  error => {
    const errorData = { message: '통신 장애가 발생하였습니다.<br/>잠시 후 다시 시도해주세요.' };
    if (!error.response) {
      // 통신 자체가 에러난 경우. 예) 인터넷이 되있지 않음.
      appManager.setSystemError(errorData);
    } else {
      const { loading, skipErrorHandler } = error.response.config as TmapAxiosRequestConfig;
      if (loading) appManager.globalLoadingDec();
      if (!skipErrorHandler) appManager.setSystemError(errorData);
    }
    return Promise.reject(error);
  },
);

const tmapApi = {
  getMdnByAccessKey(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/etc/auth/finduserinfobyaccesskey', params, config);
  },
  getBannerList(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/advertise/findbandbanner', params, config);
  },
  getPaymentServiceAgreementsChecked(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/terms/findspecifictermsagreements', params, config);
  },
  updatePaymentServiceAgreementsChecked(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/terms/updatespecifictermsagreements', params, config);
  },
  getProductList(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/products/findlist', params, config);
  },
  getProductDetail(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/products/finddetail', params, config);
  },
  getPurchaseList(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/purchase/findlist', params, config);
  },
  registerPayment(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/purchase/registerpayment', params, config);
  },
  getCouponProductList(params?: any, config?: any) {
    return $tmapAxios.post('/api-celeb/products/findlist', params, config);
  }
};

export { tmapApi, $tmapAxios };
