export function LogBox() {
    const token = "3AF19605D63E";
    const appId = "tmap";
    const serviceId = "star-voice";
    const logVersion = "1.0";
    const logboxVersion = "1.0.3";
    const tlaUrl = "https://tla-stg.tmap.co.kr:8988";
    const logTypes = Object.freeze({EVENT: 0, APP: 1, EXPOSE: 2})

    let logType = logTypes.EVENT;
    let tlaPathPrefix = "/api/v1/log/";
    let tlaPathPostfix = "?data=";


    function setBasicCommon(){
        commonLine = {};
        commonLine["token"] = token;
        commonLine["log_version"] = logVersion;
        commonLine["logbox_version"] = logboxVersion;
        line['common'] = commonLine;

        metaLine = {};
        metaLine["app_id"] = appId;
        metaLine["service_id"] = serviceId;
        line['meta'] = metaLine;
    }

    function cleanLines(){
        line = {};
    }

    const common = [
		"sub_session_id",
		"local_time",
		"app_version",
		"ip",
		"logbox_version",
		"session_id",
		"log_version",
		"token"];
    const env = [
		"document_title",
		"screen_width",
		"device_id",
		"device_model",
		"os_version",
		"resolution",
		"url",
		"manufacturer",
		"language_code",
		"browser_name",
		"referrer",
		"screen_height",
		"os_name",
		"browser_version",
		"network_type",
		"carrier_name"];
    const metaEvent = [
		"page_id",
		"section_id",
		"page_type",
		"action_id",
		"service_id",
		"app_id"];
    const metaApp = [
		"service_id",
		"app_id"];
    const metaExpose = [
		"page_id",
		"page_type",
		"service_id",
		"app_id"];
    let meta = metaEvent;
    const custom = [
		"ad_code",
		"index"];

    let logBox = {};
    let line = {};

    let commonLine = {};
    let envLine = {};
    let metaLine = {};
    let customLine = {};

    setBasicCommon();

    function getTLAPath() {
        return tlaPathPrefix + Object.getOwnPropertyNames(logTypes)[logType].toLowerCase() + tlaPathPostfix;
    }

    function deleteUnusedKey(){
        Object.keys(line["meta"]).forEach(key=>{
            if (meta.indexOf(key) === -1){
                delete line["meta"][key];
            }
        })
    }

    // ##### common ##### 
    logBox.setSub_session_id = function (value) {
        commonLine["sub_session_id"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getSub_session_id = function () {
        return commonLine["sub_session_id"];
    }


    logBox.setLocal_time = function (value) {
        commonLine["local_time"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getLocal_time = function () {
        return commonLine["local_time"];
    }


    logBox.setApp_version = function (value) {
        commonLine["app_version"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getApp_version = function () {
        return commonLine["app_version"];
    }


    logBox.setIp = function (value) {
        commonLine["ip"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getIp = function () {
        return commonLine["ip"];
    }



    logBox.getLogbox_version = function () {
        return commonLine["logbox_version"];
    }


    logBox.setSession_id = function (value) {
        commonLine["session_id"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getSession_id = function () {
        return commonLine["session_id"];
    }



    logBox.getLog_version = function () {
        return commonLine["log_version"];
    }



    logBox.getToken = function () {
        return commonLine["token"];
    }



// ##### env ##### 
    logBox.setDocument_title = function (value) {
        envLine["document_title"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDocument_title = function () {
        return envLine["document_title"];
    }


    logBox.setScreen_width = function (value) {
        envLine["screen_width"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getScreen_width = function () {
        return envLine["screen_width"];
    }


    logBox.setDevice_id = function (value) {
        envLine["device_id"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDevice_id = function () {
        return envLine["device_id"];
    }


    logBox.setDevice_model = function (value) {
        envLine["device_model"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDevice_model = function () {
        return envLine["device_model"];
    }


    logBox.setOs_version = function (value) {
        envLine["os_version"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getOs_version = function () {
        return envLine["os_version"];
    }


    logBox.setResolution = function (value) {
        envLine["resolution"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getResolution = function () {
        return envLine["resolution"];
    }


    logBox.setUrl = function (value) {
        envLine["url"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getUrl = function () {
        return envLine["url"];
    }


    logBox.setManufacturer = function (value) {
        envLine["manufacturer"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getManufacturer = function () {
        return envLine["manufacturer"];
    }


    logBox.setLanguage_code = function (value) {
        envLine["language_code"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getLanguage_code = function () {
        return envLine["language_code"];
    }


    logBox.setBrowser_name = function (value) {
        envLine["browser_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getBrowser_name = function () {
        return envLine["browser_name"];
    }


    logBox.setReferrer = function (value) {
        envLine["referrer"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getReferrer = function () {
        return envLine["referrer"];
    }


    logBox.setScreen_height = function (value) {
        envLine["screen_height"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getScreen_height = function () {
        return envLine["screen_height"];
    }


    logBox.setOs_name = function (value) {
        envLine["os_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getOs_name = function () {
        return envLine["os_name"];
    }


    logBox.setBrowser_version = function (value) {
        envLine["browser_version"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getBrowser_version = function () {
        return envLine["browser_version"];
    }


    logBox.setNetwork_type = function (value) {
        envLine["network_type"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getNetwork_type = function () {
        return envLine["network_type"];
    }


    logBox.setCarrier_name = function (value) {
        envLine["carrier_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getCarrier_name = function () {
        return envLine["carrier_name"];
    }



// ##### meta ##### 
    logBox.setPage_id = function (value) {
        metaLine["page_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getPage_id = function () {
        return metaLine["page_id"];
    }


    logBox.setSection_id = function (value) {
        metaLine["section_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getSection_id = function () {
        return metaLine["section_id"];
    }


    logBox.setPage_type = function (value) {
        metaLine["page_type"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getPage_type = function () {
        return metaLine["page_type"];
    }


    logBox.setAction_id = function (value) {
        metaLine["action_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getAction_id = function () {
        return metaLine["action_id"];
    }



    logBox.getService_id = function () {
        return metaLine["service_id"];
    }



    logBox.getApp_id = function () {
        return metaLine["app_id"];
    }



// ##### custom ##### 
    logBox.setAd_code = function (value) {
        customLine["ad_code"] = value;
        line["custom"] = customLine;
        return logBox;
    }

    logBox.getAd_code = function () {
        return customLine["ad_code"];
    }


    logBox.setIndex = function (value) {
        customLine["index"] = value;
        line["custom"] = customLine;
        return logBox;
    }

    logBox.getIndex = function () {
        return customLine["index"];
    }





    logBox.selectLogTypeAsEvent = function (){
        logType = logTypes.EVENT;
        meta = metaEvent;
    }

    logBox.selectLogTypeAsApp = function (){
        logType = logTypes.APP;
        meta = metaApp;
    }

    logBox.selectLogTypeAsExpose = function (){
        logType = logTypes.EXPOSE;
        meta = metaExpose;
    }

    logBox.addCustomField = function (key, value) {
        customLine[key] = value;
    }

    logBox.getCustomFieldAllJSONObject = function (){
        return customLine;
    }

    logBox.getCustomFieldAllJSONString = function (){
        return JSON.stringify(customLine);
    }

    logBox.getCustomFieldAll = function (){
        return customLine;
    }

    line['common'] = commonLine;
    line['env'] = envLine;
    line['meta'] = metaLine;
    line['custom'] = customLine;

    logBox.getJSONObject = function () {
        deleteUnusedKey();
        return JSON.parse(JSON.stringify(line));
    }

    logBox.getJSONString = function () {
        deleteUnusedKey();
        return JSON.stringify(line);
    }

    logBox.getJSONStringEncodeURI = function (){
        deleteUnusedKey();
        return encodeURIComponent(JSON.stringify(line));
    }

    logBox.getCommonFields = function (){
        return common.slice();
    }

    logBox.getEnvFields = function (){
        return env.slice();
    }

    logBox.getMetaFields = function (){
        return meta.slice();
    }

    logBox.getCustomFields = function (){
            return custom.slice();
    }

    logBox.clear = function(){
        cleanLines();
        setBasicCommon();
    }

    logBox.getTlaURL = function(){
        return tlaUrl;
    }

    logBox.getTlaPath = function(){
        return getTLAPath();
    }

    logBox.getTlaURI = function(){
        deleteUnusedKey();
        return tlaUrl+getTLAPath()+JSON.stringify(line);
    }
    logBox.getTlaURIEncode = function(){
        deleteUnusedKey();
        return tlaUrl+getTLAPath()+encodeURIComponent(JSON.stringify(line));
    }
    return logBox;
}