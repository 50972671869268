import React, { useCallback } from 'react';
import { eventBus } from '../../../libs/event-bus';
import { ModalEvent } from '../index';

interface Props {
  callback?: Nullable<(a: any) => any>;
  title?: string;
  content: string;
}

function Alert(props: Props) {
  const onClickButton = useCallback(() => {
    eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
      if (props.callback) props.callback(true);
    });
  }, [props]);

  return (
    <div className="modal_wrap">
      <div className="modal_container">
        {props.title && (
          <header className="modal_header">
            <h1 className="title">{props.title}</h1>
          </header>
        )}
        <div className="modal_content">
          <p className="modal_msg" dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
        <footer className="modal_footer">
          <button type="button" className="btn_confirm" onClick={onClickButton}>
            확인
          </button>
        </footer>
      </div>
      <div className="modal_dimmed" onClick={onClickButton} />
    </div>
  );
}

export { Alert };
