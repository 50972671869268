import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { voiceManager } from '../../../managers';

function DefaultVoiceList() {
  const voiceState = useSelector((state: RootState) => state.voice);
  const selectedGuideType = useSelector((state: RootState) => state.voice.selectedGuideType);

  return (
    <ul className="voice_type_list">
      {voiceState.defaultProductList.map(itemData => (
        <li className="voice_type_item" key={itemData.productId}>
          <div className="radio_area">
            <input
              type="radio"
              name="voice_type"
              className="blind"
              id={`voice_${itemData.productId}`}
              onChange={() => voiceManager.setGuideType(itemData)}
              defaultChecked={selectedGuideType === itemData.productId}
              data-logbox-action={`tap.${itemData.productId}.voice`}
            />
            <label className="label" htmlFor={`voice_${itemData.productId}`}>
              <span className="text">{itemData.name}</span>
            </label>
          </div>
        </li>
      ))}
    </ul>
  );
}

export { DefaultVoiceList };
