import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { voiceManager } from '../../../managers';
import { ProductItem } from '../../../types/voice';

interface Props {
  promotionId: string;
  itemData: ProductItem;
  onClickUse(itemData: ProductItem): void
}

function CelebVoiceCouponItem({ promotionId, itemData, onClickUse }: Props) {
  const voiceState = useSelector((state: RootState) => state.voice);
  const usagePeriod = useMemo(() => {
    return itemData.usagePeriod === '365' ? '1년' : `${itemData.usagePeriod}일`;
  }, [itemData.usagePeriod]);

  const isNew = useMemo(() => itemData.badge === 'new', [itemData]);
  const isPlaying = useMemo(() => itemData.productId === voiceState.playingGuideType, [voiceState.playingGuideType, itemData]);
  const buttonClassName = useMemo(() => {
    const result = [];
    if (isNew) result.push('new');
    if (isPlaying) result.push('tts');
    return result.join(' ');
  }, [isPlaying, isNew]);

  return (
    <li className="celeb_voice_item">
      <div className="celeb_voice_thumb">
        {/* 신규 : new, 음원플레이 : tts */}
        <button type="button" className={`thumb ${buttonClassName}`} onClick={() => voiceManager.playTTS(itemData, promotionId)}>
          {itemData.cover.thumbnailUrl ? <img src={itemData.cover.thumbnailUrl} width="74" className="img" height="74" alt="" /> : null}
          <span className="animate">
            <img src={require('../../../assets/images/play.gif').default} className="ani" alt="" />
          </span>
        </button>
      </div>
      {/* 판매중인상품 */}
      <button
        type="button"
        className="celeb_voice_info"
        onClick={() => onClickUse(itemData)}
        data-logbox-action="tap.product.celeb"
        data-logbox-custom={JSON.stringify({ product_id: itemData.productId, promotion_id: promotionId })}
      >
        <div className="title">
          <p className="name">{itemData.name}</p>
        </div>
        <p className="desc">{itemData.description}</p>
        <p className="period">이용기간: {usagePeriod}</p>
        <span className="btn_use">이용하기</span>
      </button>
    </li>
  );
}
export { CelebVoiceCouponItem };
