import qs from 'qs';
import commaNumber from 'comma-number';
import dateformat from 'date-format';
import { eventBus } from './event-bus';
import { tmapInterface } from './tmap-interface';
import { AppSchemeParams, globalVars } from './global-vars';
import { HistoryManagerEvent } from '../components/HistoryManager';
import urlModule from 'url'
import compareVersions from 'compare-versions';
import { customAlphabet } from 'nanoid'
import { alphanumeric } from 'nanoid-dictionary'

type DateTextType = 'date' | 'time' | 'full' | null;

const utils = {
  // 서버에서 주는 형식을 표현텍스트로 변환 20210614235959 => 2021.06.14 23:59:59
  dateText(value: string, type: DateTextType = 'date') {
    switch (type) {
      case 'full':
        return value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3 $4:$5:$6');
      case 'time':
        return value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/, '$1.$2.$3 $4:$5');
      case 'date':
      default:
        return value.replace(/^(\d{4})(\d{2})(\d{2}).*/, '$1.$2.$3');
    }
  },
  dateString(value: string, type: DateTextType) {
    return this.dateText(value, type).replace(/\./g, '/');
  },
  comma(value: string | number) {
    return commaNumber(value);
  },
  getOffsetTop(el: HTMLElement, result = 0): number {
    if (typeof el.offsetTop !== 'number') return result;
    result += el.offsetTop;
    return el.offsetParent ? this.getOffsetTop(el.offsetParent as HTMLElement, result) : result;
  },
  dateToText(date?: Nullable<Date>) {
    if (!date) date = new Date();
    return dateformat('yyyyMMddhhmmss', date);
  },
  dateDiffToDay(startDate: string, endDate: string) {
    if(!startDate || !endDate) return 0;

    const sDate = new Date(this.dateString(startDate, 'date')).getTime();
    const eDate = new Date(this.dateString(endDate, 'date')).getTime();

    return ((eDate - sDate) / (1000 * 60 * 60 * 24) + 1);
  },
  generateToken(type: string) {
    const generateAlphanumeric = customAlphabet(alphanumeric, 44)
    return `${type}-${generateAlphanumeric()}-${this.dateToText()}`
  },
  linkToCustomUrl(url: string) {
    if (!url) return
    const urlData = urlModule.parse(url)
    const isTmapService = urlData.protocol?.startsWith('tmap')
    const isRegisteredService = ['oil-discount', 'shopping', 'promotion', 'star-voice', 'customer-center', 'driving-habit', 'plus'].includes(
      urlData.hostname || '',
    )

    const openServiceByNameVersionCheck = compareVersions(globalVars.appVersion, '8.7.0') >= 0
    if (isTmapService && isRegisteredService && openServiceByNameVersionCheck) {
      if (urlData.hostname === 'star-voice') {
        const queryData: AppSchemeParams = qs.parse(urlData.search || '', { ignoreQueryPrefix: true })
        if (queryData.pageid === 'celeb') eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, `/voice/celeb/${queryData.productid}`);
        else tmapInterface.openBrowser(url)
      } else {
        const qsData = qs.parse(urlData.query || '')
        tmapInterface.openServiceByName(urlData.hostname || '', JSON.stringify(qsData))
      }
    } else {
      tmapInterface.openBrowser(url)
    }
  },
  linkToAppDownload() {
    tmapInterface.openBrowser('https://play.google.com/store/apps/details?id=com.skt.tmap.ku&launch=true');
  },
};

export { utils };
