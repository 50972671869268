import axios from 'axios'
import { LogboxLib } from './types'
import dateformat from 'date-format'
import { forEach } from 'lodash-es'
import { store } from '../../store'
import { globalVars } from '../global-vars'
import UAParser from 'ua-parser-js'

type LogboxInstance = ReturnType<typeof LogboxLib>

const envStr = process.env.REACT_APP_BUILD_ENV === 'PRD' ? 'PROD' : 'STG'
const { LogBox } = require(`./${envStr}-mix-tmap-star-voice-logbox`)

class TmapLogBox {
  parserData: ReturnType<typeof UAParser>
  currentPageId = ''
  fixedCustomFieldData = {}

  constructor() {
    this.parserData = UAParser(navigator.userAgent)
  }

  getLogboxLib() {
    const defaultStr = 'UNKNOWN'
    const lib: LogboxInstance = new LogBox()
    lib
      .setOs_version(this.parserData.os.version || defaultStr)
      .setOs_name(this.parserData.os.name || defaultStr)
      .setManufacturer(this.parserData.device.vendor || defaultStr)
      .setDevice_model(this.parserData.device.model || defaultStr)
      .setBrowser_name(this.parserData.browser.name || defaultStr)
      .setBrowser_version(this.parserData.browser.version || defaultStr)
      .setLanguage_code(navigator.language)
      .setUrl(window.location.href)
      .setReferrer(document.referrer)
      .setDocument_title(document.title)
      .setResolution(`${window.screen.width}*${window.screen.height}`)
      .setScreen_width(window.screen.width)
      .setScreen_height(window.screen.height)
      .setLocal_time(dateformat('yyyyMMddhhmmssSSS', new Date()))
      .setPage_type('web')
      .setCarrier_name(globalVars.carrierName)
      .setDevice_id(globalVars.deviceId)
      .setIp('')
      .setSession_id(globalVars.sessionId)
      .setApp_version(globalVars.appVersion)
      .setNetwork_type('')

    forEach(this.fixedCustomFieldData, (value, key) => lib.addCustomField(key, value))

    return lib
  }

  setFixedCustomField(data: any) {
    this.fixedCustomFieldData = data
  }

  sendLog(lib: LogboxInstance) {
    return axios.get(lib.getTlaURIEncode())
  }

  sendEvent(actionId: string, customField?: { [k: string]: string }) {
    const lib = this.getLogboxLib()
    lib.setPage_id(this.currentPageId).setAction_id(actionId).selectLogTypeAsEvent()
    if (customField) forEach(customField, (value, key) => lib.addCustomField(key, value))
    this.printLog('EVENT', lib, this.currentPageId, actionId, customField)
    return this.sendLog(lib)
  }

  sendExpose() {
    const lib = this.getLogboxLib()
    lib.setPage_id(this.currentPageId).selectLogTypeAsExpose()
    this.printLog('EXPOSE', lib, this.currentPageId)
    return this.sendLog(lib)
  }

  sendApp() {
    const lib = this.getLogboxLib()
    lib.selectLogTypeAsApp()
    this.printLog('APP', lib)
    return this.sendLog(lib)
  }

  setCurrentPageId(pageId: string) {
    this.currentPageId = pageId
  }

  printLog(type: string, lib: LogboxInstance, ...args: any[]) {
    if(store.getState().app.isDevelopmentBuild) console.log('logbox -', type, args, lib.getJSONObject())
  }
}

const tmapLogbox = new TmapLogBox()
export { tmapLogbox }
