import qs from 'qs';

export interface AppSchemeParams {
  pageid?: string;
  productid?: string;
  pageId?: string;
  productId?: string;
}
export interface QueryData extends AppSchemeParams {
  ak?: string;
  guideType?: string;
  [propsName: string]: any;
}

// IOS에서 pageid와 productI가 camelCase로 오는 경우가 있음
const queryData: QueryData = qs.parse(window.location.search.replace(/^\?/, '')) as QueryData;
queryData.pageid = queryData.pageid || queryData.pageId
queryData.productid = queryData.productid || queryData.productId

const userAgent = navigator.userAgent;
const accessKey = queryData.ak || localStorage.getItem('ak');
const isIOS = /ipad|iphone/i.test(userAgent);
const isAndroid = /android/i.test(userAgent);
const isInApp = /\/tmap\//i.test(userAgent);

// app version 추출
let appVersion;
if (isInApp && isIOS) appVersion = userAgent.replace(/.*\/ios\/([\d.]*).*/i, '$1');
else if (isInApp && isAndroid) appVersion = userAgent.replace(/.*\/android\/([\d.]*).*/i, '$1');
else appVersion = process.env.REACT_APP_TMAP_APP_VERSION || '8.5.0';

if (queryData.ak) localStorage.setItem('ak', queryData.ak);

const userWebUrl = process.env.REACT_APP_UW_URL;
const globalVars = {
  queryData,
  accessKey,
  userAgent,
  mdn: '',
  isLocal: process.env.NODE_ENV === 'development',
  isDebug: process.env.REACT_APP_DEBUG_VIEW === 'Y',
  isInApp,
  isIOS,
  isAndroid,
  isGreenApp: /\/KU\//.test(userAgent),
  isRedApp: /\/SK\//.test(userAgent),
  isDevelopmentBuild: /STG|DTG|DEV/.test(process.env.REACT_APP_BUILD_ENV || '') || process.env.NODE_ENV === 'development',
  appVersion,
  userWebUrl,
  userWebFaqUrl: `${userWebUrl}/m/cs/faq?ak=${accessKey}`,
  testProductEnabled: process.env.REACT_APP_DEBUG_VIEW === 'Y',
  webBuildNumber: `${process.env.REACT_APP_BUILD_ENV}_${process.env.REACT_APP_BUILD_NUMBER}`,
  userEUK: '',
  /*
    앱에서 처음 셀럽을 열었는지 판단.
    querystring으로 데이터(guidetype, pageid, productid)를 전달받기 때문에
    외부페이지 이동 후 history.back()시 이미 변경된 상태를 querystring내용으로 다시 덮을 수 있음.
  */
  isFirstLanding: true,
  sessionId: '',
  deviceId: '',
  carrierName: '',
};

export { globalVars };
