import React, { useState, useEffect } from 'react';
import { Header } from '../../components/Layout';
import { VoiceServiceTerms } from '../../components/Terms/VoiceService';

function VoiceTerms() {
  const [inited, setInited] = useState(false);

  useEffect(() => {
    setInited(true);
  }, []);

  return (
    <>
      <Header title={'이용약관'} />
      {inited && (
        <div className="content">
          <section className="terms">
            <h1 className="terms_title">TMAP 셀럽 서비스 이용약관</h1>
            <div className="terms_content">
              <VoiceServiceTerms />
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export { VoiceTerms };
