import { useCallback, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useListenEventBus } from '../libs/custom-hooks';
import { globalVars } from '../libs/global-vars';

export enum HistoryManagerEvent {
  PUSH_HISTORY = 'PUSH_HISTORY',
  REPLACE_HISTORY = 'REPLACE_HISTORY',
  GO_BACK = 'GO_BACK',
  CHANGE_START = 'CHANGE_START',
}

// react 외부에서 히스토리 변경 요청용
const HistoryManager = withRouter(function ({ history }) {
  const landingPath = useRef<string>(history.location.pathname)
  const onRequestPushHistory = useCallback(
    (url, state) => {
      history.push(url, state);
    },
    [history],
  );
  const onRequestReplaceHistory = useCallback(
    (url, state) => {
      history.replace(url, state);
    },
    [history],
  );
  const onRequestGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    const pathChanged = history.location.pathname !== landingPath.current
    if (globalVars.isFirstLanding && pathChanged) globalVars.isFirstLanding = false
  }, [history.location.pathname])

  useListenEventBus(HistoryManagerEvent.PUSH_HISTORY, onRequestPushHistory);
  useListenEventBus(HistoryManagerEvent.REPLACE_HISTORY, onRequestReplaceHistory);
  useListenEventBus(HistoryManagerEvent.GO_BACK, onRequestGoBack);
  return null;
});

export { HistoryManager };
