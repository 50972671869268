import React, { useState, useCallback, ReactElement } from 'react';
import { times } from 'lodash-es';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Autoplay]);

interface Props {
  children: ReactElement[];
}

function CustomSwiper(props: Props) {
  const [actionIndex, setActiveIndex] = useState(0);
  const imageLength = props.children.length;

  const onChangeSlide = useCallback(
    swiper => {
      setActiveIndex(swiper.activeIndex % props.children.length);
    },
    [props.children.length],
  );

  return (
    <Swiper onSlideChange={onChangeSlide} loop={imageLength > 1} autoplay={{ delay: 5000 }} loopAdditionalSlides={imageLength}>
      {props.children}
      {imageLength ? (
        <div className="swiper-pagination">
          {times(imageLength, n => (
            <span className={`swiper-pagination-bullet ${n === actionIndex ? 'swiper-pagination-active' : ''}`} key={`bullet${n}`}>
              {n}
            </span>
          ))}
        </div>
      ) : null}
    </Swiper>
  );
}

export { CustomSwiper, SwiperSlide };
