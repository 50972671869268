import { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { routes } from '../../App';
import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from '../../libs/tmap-interface';

const HeaderBackButton = withRouter(function ({ match, history }) {
  const targetRouteConfig = useMemo(() => {
    return routes.find(config => config.path === match.path);
  }, [match]);
  const onClickButton = useCallback(() => {
    if (targetRouteConfig) {
      const { backUrlAtFirstLanding, isSub } = targetRouteConfig;
      if (globalVars.isFirstLanding) backUrlAtFirstLanding ? history.replace(backUrlAtFirstLanding) : tmapInterface.onBackKeyPressed();
      else if (isSub) history.goBack();
      else tmapInterface.onBackKeyPressed();
    } else {
      tmapInterface.onBackKeyPressed();
    }
  }, [history, targetRouteConfig]);

  return (
    <button type="button" className="btn_back" onClick={onClickButton}>
      뒤로가기
    </button>
  );
});

export { HeaderBackButton };
