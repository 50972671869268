import { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useListenWindowEvent } from '../custom-hooks'
import { eventBus } from '../event-bus'
import { globalVars } from '../global-vars'
import { TmapInterfaceEvent } from '../tmap-interface'
import { tmapLogbox } from './tmap-log-box'

/*
app, expose, back 동작을 호출한다.
*/
export const TmapLogboxDispatcher: FC = () => {
  const history = useHistory()
  const [beforePathname, setBeforePathname] = useState('')
  const [currentPageId, setCurrentPageId] = useState('') //path 저장하는 용도

  // pageid 조합
  const setComputedPageId = useCallback((pathname: string) => {
    let result = ''
    const schemePageIdArr = (globalVars.queryData.pageid || globalVars.queryData.pageId || '').split('_')
    const isMain = pathname === '/voice' || pathname === '/voice/'
    const isCelebDetail = pathname.startsWith('/voice/celeb')
    const isCoupon = pathname.startsWith('/voice/coupon')
    const pageName = (isMain || isCoupon) ? 'main' : 'celeb'
    const visitType = schemePageIdArr[1] || 'gnb'
    const originId = schemePageIdArr.slice(2).join('_') || ''
    
    if (isMain || isCelebDetail || isCoupon) {
      result = `${pageName}_${visitType}`
      if (originId) result += `_${originId}`
    } else {
      result = pathname.replace('/voice/', '')
    }
    setCurrentPageId(result)
    setBeforePathname(pathname)

    // 고정 custom field
    const fixedCustomField: Partial<{type: string, origin: string, product_id: string, euk: string}> = {}
    if (visitType) fixedCustomField.type = visitType
    if (originId) fixedCustomField.origin = originId
    if (isCelebDetail) fixedCustomField.product_id = pathname.replace('/voice/celeb/', '')
    if (globalVars.userEUK) fixedCustomField.euk = globalVars.userEUK
    tmapLogbox.setFixedCustomField(fixedCustomField)
  }, [])

  useEffect(() => {
    const unlisten = history.listen((nextLocation, action) => {
      const isChangedPathname = nextLocation.pathname !== beforePathname
      if (isChangedPathname) {
        if (action === 'POP') tmapLogbox.sendEvent('tap.back')
        setComputedPageId(nextLocation.pathname)
      }
    })
    return () => {
      unlisten()
    }
  }, [beforePathname, history, setComputedPageId])


  // tmapInterface onBackKeyPressed 호출되면 back키를 누른 것으로 인식.
  const onCalledInterface = useCallback((method: string) => method === 'onBackKeyPressed' && tmapLogbox.sendEvent('tap.back'), [])
  useEffect(() => {
    eventBus.on(TmapInterfaceEvent.CALL_INTERFACE, onCalledInterface)
    return () => {
      eventBus.off(TmapInterfaceEvent.CALL_INTERFACE, onCalledInterface)
    }
  }, [onCalledInterface])

  // 붙으면 일단 app 호출, 초기페이지 호출
  useEffect(() => {
    setComputedPageId(history.location.pathname)
    tmapLogbox.sendApp()
  }, [history, setComputedPageId])

  // pageId변경되면 expose 호출하고
  useEffect(() => {
    tmapLogbox.setCurrentPageId(currentPageId)
    if (currentPageId) tmapLogbox.sendExpose()
  }, [currentPageId])

  // 이벤트 델리게이션 걸어서 datasetting한 버튼들 클릭한거 event 호출하고
  useListenWindowEvent(
    'click',
    (e: MouseEvent) => {
      const targetEl = e.target as HTMLElement
      const logboxEl = targetEl.closest('[data-logbox-action]') as HTMLElement
      if (logboxEl) {
        const dataset = logboxEl.dataset as { logboxAction: string; logboxCustom: Nullable<string> }
        const custom = dataset.logboxCustom ? JSON.parse(dataset.logboxCustom) : null
        tmapLogbox.sendEvent(dataset.logboxAction, custom)
      }
    },
    true,
  )

  return null
}
