import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { tmapInterface } from '../../libs/tmap-interface';

function Footer() {
  const [tipOpen, setTipOpen] = useState(false);

  return (
    // 상세에서 footer 제외
    <footer className="footer">
      <div className="customer">
        <button className="customer_item" data-logbox-action="tap.cs.celeb" onClick={() => tmapInterface.openServiceByName('customer-center')}>
          고객센터
        </button>
        <Link to="/voice/terms" className="customer_item" data-logbox-action="tap.policy.celeb">
          이용약관
        </Link>
      </div>
      <div className="company">
        <button
          type="button"
          className={`btn_company_info ${tipOpen ? 'show' : ''}`}
          onClick={() => setTipOpen(!tipOpen)}
          data-logbox-action="tap.tmapmobilityco."
        >
          티맵모빌리티(주) 사업자 정보
        </button>
        {tipOpen ? (
          <ul className="company_info">
            <li className="company_info_item">
              <em className="tit">대표이사</em>
              <p className="desc">이종호</p>
            </li>
            <li className="company_info_item">
              <em className="tit">주소</em>
              <p className="desc">서울특별시 중구 삼일대로 343, 12층, 13층, 14층 (저동1가, 대신파이낸스센터)</p>
            </li>
            <li className="company_info_item">
              <em className="tit">사업자 등록 번호</em>
              <p className="desc">640-88-02105</p>
            </li>
            <li className="company_info_item">
              <em className="tit">통신판매 사업신고</em>
              <p className="desc">제2021-서울종로-0228호</p>
            </li>
            <li className="company_info_item">
              <em className="tit">전화번호</em>
              <p className="desc">02-6370-5240</p>
            </li>
            <li className="company_info_item">
              <em className="tit">이메일</em>
              <p className="desc">cs@tmap.co.kr</p>
            </li>
          </ul>
        ) : null}
      </div>
    </footer>
  );
}

export { Footer };
