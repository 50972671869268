import React, { MutableRefObject, useMemo } from 'react';
import { FadeInByScroll } from '../FadeInByScroll';
import { HeaderBackButton } from './HeaderBackButton';

interface Props {
  thumbnailUrl: string;
  imageRef: MutableRefObject<HTMLElement | null>;
}

function HeaderVoiceDetail({ thumbnailUrl, imageRef }: Props) {
  const fadeInOptions = useMemo(() => ({ targetRef: imageRef, checkPoint: -100, applyDistance: 70 }), [imageRef]);
  return (
    <header className="header">
      <FadeInByScroll className="thumb" style={{ display: 'block' }} {...fadeInOptions}>
        <img src={thumbnailUrl} width="36" height="36" alt="" />
      </FadeInByScroll>
      <HeaderBackButton />
      <FadeInByScroll className="bg" {...fadeInOptions} />
    </header>
  );
}

export { HeaderVoiceDetail };
