import React from 'react';
import { utils } from '../../../libs/utils';
import { ProductItem } from '../../../types/voice';

interface Props {
  itemData: ProductItem;
  type?: 'LIST' | 'DETAIL';
}

function Price({ itemData, type }: Props) {
  const { basePrice, sellingPrice } = itemData.sale.price;
  const discounted = basePrice && sellingPrice && basePrice > sellingPrice;
  const percentage = 100 - Math.round((sellingPrice / basePrice) * 100);
  const isDetail = type === 'DETAIL';

  const renderSellingPrice = () => {
    return <p className="price">{utils.comma(sellingPrice)}원</p>;
  };

  if (sellingPrice === 0) {
    return <p className={'price'}>무료</p>;
  } else {
    return (
      <>
        {isDetail && renderSellingPrice()}
        {discounted && (
          <p className={`sale`}>
            <span className="won">{utils.comma(basePrice)}원</span>
            <span className="rate">{percentage}%</span>
          </p>
        )}
        {!isDetail && renderSellingPrice()}
      </>
    );
  }
}

export { Price };
